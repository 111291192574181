<template>
  <div>
    <v-app>
      <b-form @submit.stop.prevent="formOnsubmit">
        <!-- Input Stock In Out -->
        <b-form-group
          id="input-group-stock-in-out"
          label="Jenis Stok:"
          label-for="input-stock-in-out"
        >
          <b-form-select
            v-model="form.stock_in_out"
            :options="options"
            @input="setFormStockInOut"
          ></b-form-select>
          <small class="text-danger">{{ error.stock_in_out }}</small>
        </b-form-group>

        <!-- Input Medicine -->
        <!-- <b-form-group
          id="input-group-medicine"
          label="Obat:"
          label-for="input-medicine"
        >
          <treeselect
            v-model="form.medicine_id"
            :multiple="false"
            placeholder="Obat"
            :options="medicine"
          />
          <small class="text-danger">{{ error.medicine_id }}</small>
        </b-form-group> -->

        <b-form-group
          id="input-group-trigger-modal-medicine"
          label="Obat:"
          label-for="input-trigger-modal-medicine"
        >
          <b-form-input
            id="input-trigger-modal-medicine"
            v-model="display.medicine_name"
            placeholder="Pilih Obat"
            readonly
            @click="$bvModal.show('modal-medicine')"
          >
          </b-form-input>
          <small class="text-danger">{{ error.medicine_id }}</small>
        </b-form-group>

        <!-- Input Quantity -->
        <b-form-group
          id="input-group-quantity"
          label="Kuantitas:"
          label-for="input-quantity"
        >
          <b-form-input
            id="input-quantity"
            v-model="form.quantity"
            placeholder="Kuantitas"
          >
          </b-form-input>
          <small class="text-danger">{{ error.quantity }}</small>
        </b-form-group>

        <!-- Input Reference No -->
        <b-form-group id="input-group-ref-no" v-if="stock_in">
          <label for="input-ref-no"
            >Reference No: <em class="text-muted">opsional</em></label
          >
          <b-form-input
            id="input-ref-no"
            v-model="form.ref_no"
            placeholder="Reference No"
          >
          </b-form-input>
          <small class="text-danger">{{ error.ref_no }}</small>
        </b-form-group>

        <!-- Input Price -->
        <b-form-group
          id="input-group-hna"
          label="Harga Netto Apotek:"
          label-for="input-hna"
          v-if="stock_in"
        >
          <b-form-input
            id="input-hna"
            v-model="formatedHna"
            placeholder="Harga Netto Apotek"
          >
          </b-form-input>
          <small class="text-danger">{{ error.hna }}</small>
        </b-form-group>

        <!-- Input Discount -->
        <b-form-group
          id="input-group-discount"
          label="Diskon(%):"
          label-for="input-discount"
          v-if="stock_in"
        >
          <b-form-input
            id="input-discount"
            v-model="form.discount"
            placeholder="Diskon"
          >
          </b-form-input>
          <small class="text-danger">{{ error.discount }}</small>
        </b-form-group>

        <!-- Input PPN -->
        <b-form-group
          id="input-group-ppn"
          label="PPN(%):"
          label-for="input-ppn"
          v-if="stock_in"
        >
          <b-form-input id="input-ppn" v-model="form.ppn" placeholder="PPN">
          </b-form-input>
          <small class="text-danger">{{ error.ppn }}</small>
        </b-form-group>

        <!-- Input Company -->
        <b-form-group id="input-group-vendor" v-if="stock_in">
          <label for="input-vendor"
            >Supplier: <em class="text-muted">opsional</em></label
          >
          <treeselect
            v-model="form.vendor_id"
            :multiple="false"
            :options="vendors"
          />
          <small class="text-danger">{{ error.vendor_id }}</small>
        </b-form-group>

        <!-- Input Batch No -->
        <b-form-group id="input-group-batch-no" v-if="stock_in">
          <label for="input-vendor">Batch</label>
          <div class="row">
            <div class="col-md-2">
              <b-form-input
                id="input-batch-no"
                v-model="batch_collection_form.batch_no"
                placeholder="Batch No"
              >
              </b-form-input>
              <small class="text-danger">{{ error_batch.batch_no }}</small>
            </div>
            <div class="col-md-2">
              <b-form-input
                id="input-quantity-batch"
                v-model="batch_collection_form.first_quantity"
                placeholder="Jumlah"
              >
              </b-form-input>
              <small class="text-danger">{{
                error_batch.first_quantity
              }}</small>
            </div>
            <div class="col-md-2">
              <treeselect
                v-model="batch_collection_form.warehouse"
                :multiple="false"
                :options="warehouse"
                placeholder="Gudang"
              />
              <small class="text-danger">{{ error_batch.warehouse }}</small>
            </div>

            <div class="col-md-2" v-if="warehouseChoosed">
              <treeselect
                v-model="batch_collection_form.rack"
                :multiple="false"
                :options="rack"
                placeholder="Rak"
              />
              <small class="text-danger">{{ error_batch.rack_id }}</small>
            </div>

            <div class="col-md-3">
              <b-form-group id="input-group-expired">
                <v-dialog
                  ref="dialog"
                  v-model="modal2"
                  :return-value.sync="batch_collection_form.expired"
                  persistent
                  width="350px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <b-input-group>
                      <b-input-group-prepend>
                        <button
                          class="btn btn-secondary"
                          type="button"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <i class="fas fa-calendar"></i>
                        </button>
                      </b-input-group-prepend>
                      <b-form-input
                        id="input-trigger-modal-patient"
                        v-model="batch_collection_form.expired"
                        placeholder="Expired(YYYY-MM-DD)"
                        @input="formaterDate"
                      >
                      </b-form-input>
                    </b-input-group>
                  </template>

                  <v-date-picker
                    v-if="modal2"
                    v-model="batch_collection_form.expired"
                    locale="id"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(batch_collection_form.expired)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>

                <!-- <v-dialog
                  ref="dialog"
                  v-model="modal2"
                  :return-value.sync="batch_collection_form.expired"
                  persistent
                  width="350px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <b-form-input
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      id="input-trigger-modal-patient"
                      v-model="batch_collection_form.expired"
                      placeholder="Kadaluarsa"
                    >
                    </b-form-input>
                    <small class="text-danger">{{ error_batch.expired }}</small>
                  </template>

                  <v-date-picker
                    v-if="modal2"
                    locale="id"
                    v-model="batch_collection_form.expired"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(batch_collection_form.expired)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog> -->
              </b-form-group>
            </div>
            <div class="col-md-1">
              <b-button
                size="sm"
                class="btn-info"
                v-b-tooltip.hover
                title="Tambah Batch"
                type="button"
                @click="setBatch"
                ><i class="fas fa-plus px-0"></i
              ></b-button>
            </div>
          </div>
        </b-form-group>

        <div class="bg-light">
          <table class="table" v-if="stock_in">
            <tr>
              <th>Batch No</th>
              <th>Jumlah</th>
              <th>Gudang</th>
              <th>Kadaluarsa</th>
              <th>Aksi</th>
            </tr>
            <tr v-for="btc in batch_collection" :key="btc.id">
              <td>{{ btc.batch_no }}</td>
              <td>{{ btc.first_quantity }}</td>
              <td>{{ btc.warehouse_name + " - " + btc.rack_name }}</td>
              <td>{{ btc.expired }}</td>
              <td>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus Batch"
                  type="button"
                  @click="removeBatch(btc.batch_no)"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
              </td>
            </tr>
          </table>
        </div>

        <!-- Input Description -->
        <b-form-group id="input-group-notes">
          <label for="input-notes"
            >Catatan: <em class="text-muted">opsional</em></label
          >
          <b-form-textarea
            id="input-notes"
            v-model="form.notes"
            placeholder="Catatan"
            rows="4"
            max-rows="6"
          ></b-form-textarea>
          <small class="text-danger">{{ error.notes }}</small>
        </b-form-group>

        <!-- Button -->
        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          class="ml-2"
          variant="default"
          @click="$router.push('/medicine/maintain-stock')"
        >
          Batal
        </b-button>
      </b-form>
      <ModalMedicine
        @chosenMedicine="setMedicine"
        secondPurpose="medicineStock"
        :isAbjad="true"
      />
    </v-app>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalMedicine from "@/component/general/ModalMedicine.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "Add",

  props: {
    form: Object,
    route: String,
  },

  components: {
    Card,
    ModalMedicine,
  },

  data() {
    return {
      // Display Data
      display: {
        medicine_name: "",
      },
      medicine: [],
      vendors: [],
      warehouse: [],
      rack: [],
      modal2: false,
      stock_in: true,
      // Error
      error: {
        medicine_id: null,
        medicine_name: "",
        stock_in_out: null,
        quantity: null,
        remaining_stock: null,
        price: null,
        vendor_id: null,
        vendor_name: null,
        notes: "",
        hna: "",
        ppn: "",
        discount: "",
        ref_no: "",
      },
      error_batch: {
        first_quantity: "",
        expired: "",
        warehouse_id: "",
        rack_id: "",
        batch_no: "",
      },
      options: [
        { value: null, text: "Pilih Jenis Stok", disabled: true },
        { value: 0, text: "Stok Masuk" },
        { value: 1, text: "Stok Keluar" },
      ],
      route_vendor: "",
      form_vendor: {
        medicine_id: this.form.medicine_id,
        vendor_id: this.form.vendor_id,
      },
      batch_collection: [],
      batch_collection_form: {
        batch_no: "",
        first_quantity: "",
        warehouse_id: [],
        rack_id: [],
        warehouse_name: "",
        rack_name: "",
        expired: "",
      },
      quantity_count: 0,
      warehouseChoosed: false,
    };
  },

  computed: {
    formatedHna: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.hna = newValue.toLocaleString("id-ID");
        } else {
          this.form.hna = "";
        }
      },
      get() {
        return this.form.hna;
      },
    },
  },

  methods: {
    formaterDate(val) {
      if (val.length == 4) {
        this.batch_collection_form.expired =
          this.batch_collection_form.expired + "-";
      }
      if (val.length == 7) {
        this.batch_collection_form.expired =
          this.batch_collection_form.expired + "-";
      }
    },

    setMedicine(value) {
      this.form.medicine_id = value.id;
      this.display.medicine_name = value.name;
      this.$bvModal.hide("modal-medicine");
    },

    async getMedicineOption() {
      let response = await module.setTreeSelect("medicines");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.medicine = response.data;
      }
    },
    async getVendorOption() {
      let response = await module.setTreeSelect("vendors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.vendors = response.data;
      }
    },
    async getWarehouseOption() {
      let response = await module.setTreeSelect("warehouses");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.warehouse = response.data;
      }
    },
    async getRackOption(id) {
      let response = await module.setTreeSelect("warehouses-rack/" + id);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.rack = response.data;
        this.warehouseChoosed = true;
      }
    },

    setFormStockInOut() {
      console.log("set form");
      if (this.stock_in == true) {
        this.stock_in = false;
      } else if (this.stock_in == false) {
        this.stock_in = true;
      }
    },

    resetBatchForm() {
      // Object.keys(this.batch_collection_form).forEach(function (key, index) {
      //     this.batch_collection_form[key] = "";
      // });
      this.batch_collection_form.first_quantity = "";
      this.batch_collection_form.expired = "";
      this.batch_collection_form.batch_no = "";
      this.batch_collection_form.warehouse_id = null;
      this.batch_collection_form.rack_id = null;

      this.batch_collection_form.warehouse_name = null;
      this.batch_collection_form.rack_name = null;

      this.batch_collection_form.warehouse = null;
      this.batch_collection_form.rack = null;
    },
    async setBatch() {
      if (
        this.batch_collection_form.first_quantity == "" ||
        this.batch_collection_form.expired == "" ||
        this.batch_collection_form.batch_no == "" ||
        this.batch_collection_form.warehouse_id == "" ||
        this.batch_collection_form.rack_id == ""
      ) {
        if (this.batch_collection_form.first_quantity == "") {
          this.error_batch.first_quantity = "Jumlah tiap batch harus diisi";
        }
        if (this.batch_collection_form.batch_no == "") {
          this.error_batch.batch_no = "Nomer batch harus diisi";
        }
        if (this.batch_collection_form.expired == "") {
          this.error_batch.expired = "Tanggal kadaluarsa harus diisi";
        }
      } else {
        this.error_batch.first_quantity = "";
        this.error_batch.batch_no = "";
        this.error_batch.expired = "";
        this.error_batch.warehouse_id = "";

        if (this.batch_collection.length == 0) {
          this.quantity_count =
            this.quantity_count +
            parseInt(this.batch_collection_form.first_quantity);
          if (this.quantity_count <= this.form.quantity) {
            let response = await module.get(
              `warehouses/${this.batch_collection_form.warehouse_id}`
            );
            this.batch_collection_form.warehouse_name = response.name;
            let resRack = await module.get(
              `warehouses-rack/by-id/${this.batch_collection_form.rack_id}`
            );
            this.batch_collection_form.rack_name = resRack.name;

            let clone = { ...this.batch_collection_form };
            this.batch_collection.push(clone);
            console.log("batch", this.batch_collection);
            this.resetBatchForm();
          } else {
            Swal.fire({
              title: "Gagal",
              text: "Jumlah stok batch tidak boleh melebihi kuantitas obat",
              icon: "warning",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.quantity_count =
              this.quantity_count -
              parseInt(this.batch_collection_form.first_quantity);
            console.log("quantity count", this.quantity_count);
          }
        } else {
          let a;
          for (a = 0; a < this.batch_collection.length; a++) {
            if (
              this.batch_collection_form.batch_no ==
              this.batch_collection[a].batch_no
            ) {
              Swal.fire({
                title: "Gagal",
                text: "Batch No Tidak Boleh Sama",
                icon: "warning",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              // this.form.quantity = parseInt(this.form.quantity) + parseInt(this.batch_collection_form.first_quantity)
              this.quantity_count =
                this.quantity_count +
                parseInt(this.batch_collection_form.first_quantity);
              if (this.quantity_count <= this.form.quantity) {
                console.log("quantity", this.quantity_count);
                let clone = { ...this.batch_collection_form };
                this.batch_collection.push(clone);
                console.log("batch", this.batch_collection);
                this.resetBatchForm();
                break;
              } else {
                Swal.fire({
                  title: "Gagal",
                  text: "Jumlah stok batch tidak boleh melebihi kuantitas obat",
                  icon: "warning",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.quantity_count =
                  this.quantity_count -
                  parseInt(this.batch_collection_form.first_quantity);
                console.log("quantity count", this.quantity_count);
              }
            }
          }
        }
      }
    },
    removeBatch($id) {
      console.log("remove batch", $id);
      let a;
      for (a = 0; a < this.batch_collection.length; a++) {
        if (this.batch_collection[a].batch_no == $id) {
          this.quantity_count =
            this.quantity_count -
            parseInt(this.batch_collection[a].first_quantity);
          console.log("quantity count", this.quantity_count);
          this.batch_collection.splice(a, 1);
        }
      }
    },

    async formOnsubmit() {
      if (this.stock_in == true) {
        if (this.batch_collection.length == 0) {
          Swal.fire({
            title: "Gagal",
            text: "Batch Masih Kosong",
            icon: "warning",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          this.form.batch = this.batch_collection;
          console.log("form", this.form);
          // Make Request
          let response = await module.submit(this.form, this.route);
          console.log("berhasil", response);
          // Check Response
          if (response.state == "error") {
            this.error = validation.setValidationError(
              this.error,
              response.error.errors
            );
          } else {
            Swal.fire(
              response.success.title,
              response.success.message,
              "success"
            );
            this.$router.push("/medicine/maintain-stock");
          }
        }
      } else if (this.stock_in == false) {
        console.log("form", this.form);
        // Make Request
        let response = await module.submit(this.form, this.route);
        console.log("berhasil", response);
        // Check Response
        if (response.state == "error") {
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        } else {
          Swal.fire(
            response.success.title,
            response.success.message,
            "success"
          );
          this.$router.push("/medicine/maintain-stock");
        }
      }
    },
  },

  mounted() {
    this.getMedicineOption();
    this.getVendorOption();
    this.getWarehouseOption();
  },
  watch: {
    "form.medicine_name": function (newVal, oldVal) {
      this.display.medicine_name = newVal;
    },
    "batch_collection_form.warehouse": function (newVal) {
      console.log(newVal, "================ new val ================");
      this.batch_collection_form.warehouse_id = newVal;
      this.getRackOption(newVal);
    },
    "batch_collection_form.rack": function (newVal) {
      this.batch_collection_form.rack_id = newVal;
    },
  },
};
</script>

<style>
</style>